import React, { Component } from 'react';
import {Button, Col, ProgressBar, Row, Spinner} from "react-bootstrap";
import { postManualReview, getManualInput } from "../scripts/ApiCalls";
import { calculateTotalQuestions, skipQuestionsForPage, findGuideline, getWCAGUrl, getWCAGFormattedName } from "../scripts/HelperFunctions";
import ImagesContainer from '../components/ManualReviewPage/ImagesContainer';
import PropTypes from "prop-types";

class ManualReviewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            currentQuestion: 0,
            questions: null,
            errors: {},
            totalQuestions: 0,
            currentGuideline: 0,
            loadingBarProgress: 0,
            currentGuidelineQuestion: 0,
            failed_questions: [],
        };

        this.handleYes = this.handleYes.bind(this);
        this.handleNo = this.handleNo.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    /*
     * Updates the dimensions if the user resizes the page
     */
    updateDimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }

    /*
     * Handles a Yes response from the user
     */
    async handleYes() {
        await this.handleButton('Yes');
    }

    /*
     * Handles a No response from the user
     */
    async handleNo() {
        await this.handleButton('No');
    }

    /*
     * Helper function called by handleNo and handleYes functions, will add an error to the state if an error has been found.
     */
    async handleButton(buttonPressed) {

        // Initialize toSkip as 1 in case the question doesn't result in an error
        let toSkip = 1;
        let guidelineQuestions = this.state.questions[this.state.currentGuideline]['questions'];
        let currentQuestion = guidelineQuestions[this.state.currentGuidelineQuestion];
        let failed_questions = this.state.failed_questions;

        // If the question's failOn is the button pressed, then we have an error with the current question
        if (currentQuestion['failOn'] === buttonPressed) {
            failed_questions = failed_questions.concat(currentQuestion.question_id);
            this.setState({ failed_questions: failed_questions });
            // TODO: Remove skipping of questions. When saying "yes, there is an overlap", the checker skips all other overlap questions. However, this causes the user to be redirected to results after only 33% of questions are answered.

            // Calculates how many questions to skip for the current page
            if (currentQuestion.skip_on_fail === true) {
                toSkip = skipQuestionsForPage(this.state.questions, currentQuestion['pageUrl'], this.state.currentGuideline, this.state.currentGuidelineQuestion);
            }
        }

        // If we still have more questions for the current guideline, we don't change the guideline
        if (this.state.currentGuidelineQuestion + toSkip < guidelineQuestions.length) {
            this.setState({
                currentGuidelineQuestion: this.state.currentGuidelineQuestion + toSkip,
                currentQuestion: this.state.currentQuestion + toSkip,
                loadingBarProgress: Math.min((this.state.currentQuestion + toSkip) / this.state.totalQuestions * 100, 100)
            });
        }

        // If we have to skip the guideline, we check if we have more guidelines to go through
        else if (this.state.currentGuideline + 1 < this.state.questions.length) {
            this.setState({
                currentGuidelineQuestion: 0,
                currentQuestion: this.state.currentQuestion + toSkip,
                loadingBarProgress: Math.min((this.state.currentQuestion + toSkip) / this.state.totalQuestions * 100, 100),
                currentGuideline: this.state.currentGuideline + 1
            });
        }

        // If we finished all the guidelines, we send results back to the backend and reroute to the results page
        else {
            const review_id = this.props.match.params.review_id;
            let jsonString = JSON.stringify({ failed_questions: failed_questions });

            const response = await postManualReview(review_id, jsonString);
            if(response.data && !response.data.success) {
                let msg = 'There has been an issue sending manual review results to the server.';
                this.props.updateError(true, msg);
            }

            this.props.updates();
            this.setState({
                currentQuestion: this.state.totalQuestions,
                loadingBarProgress: Math.min((this.state.currentQuestion + toSkip) / this.state.totalQuestions * 100, 100)
            });
        }
    }

    async componentDidMount() {
        document.title =  'Review · ' + process.env.REACT_APP_SITE_TITLE;;
        window.addEventListener('resize', this.updateDimensions);
        this.checkManualInput(this.props.match.params.review_id, 1000, 10000);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    /**
     * Check if the results are ready/complete using increasing timeouts.
     *
     * @param {String} review_id the ID of the review.
     * @param {int} timeout how long to wait to try to retrieve results again (in ms).
     * @param {int} maxTimeout maximum wait period for timeout (in ms).
     */
    async checkManualInput(review_id, timeout, maxTimeout) {
        console.log(timeout);
        let manualInput = await getManualInput(review_id);
        if (!manualInput.error && this.props.status === 2) {
            let manualInputJSON = manualInput['data']['manual_review_page_input'];
            document.title = "Manual Review stage";
            this.setState({
                questions: manualInputJSON,
                loaded: true,
                totalQuestions: calculateTotalQuestions(manualInputJSON)
            });
        }
        else if (this.props.status !== 2) {
            this.props.updates();
            if (this.props.status !== 2) {
                setTimeout(() => {
                    const newTimeout = Math.min(timeout * 1.5, maxTimeout);
                    // If we're at the MAX_TIMEOUT, reload the page.
                    if (newTimeout >= maxTimeout) {
                        window.location.reload();
                    } else {
                        this.checkManualInput(review_id, Math.min(timeout * 1.5, maxTimeout), maxTimeout);
                    }
                }, timeout);
            }
            else {
                await this.checkManualInput(review_id, timeout, maxTimeout);
            }
        }
        else {
            let msg = "Could not retrieve the manual review input for the review with id: " + this.props.match.params.review_id;
            this.props.updateError(true, msg);
        }
    }

    render() {
        if (this.state.loaded) {
            const guidelineIndex = this.state.currentGuideline;
            const questionObject = this.state.questions[guidelineIndex]['questions'];
            const question = questionObject[this.state.currentGuidelineQuestion];
            const pageUrl = question['pageUrl'];

            let guidelineLink;
            try {
                const guideline = findGuideline(question['message']['code']);

                if (guideline) {
                    guidelineLink = (
                        <a href={getWCAGUrl(guideline)} rel="noopener noreferrer" target="_blank">
                            {guideline} - {getWCAGFormattedName(guideline)}
                            <span className="sr-only">Open WCAG guideline explanation page in new tab</span>
                        </a>
                    );
                }
            } catch(e) {
                console.error('Couldn\'t generate guideline link', e);
            }

            let extraInformation = (
                <p className="lead">
                    Question pertains to url:
                    <a href={pageUrl} rel="noopener noreferrer" target="_blank">
                        <span className="sr-only">
                            Open url in new tab:{pageUrl}
                        </span>
                        {pageUrl}
                    </a>
                </p>);
            if (guidelineLink) {
                extraInformation = (
                    <p className="lead">
                        Question pertains to WCAG success criterion {guidelineLink} and URL {" "}
                        <a href={pageUrl} rel="noopener noreferrer" target="_blank">
                            <span className="sr-only">
                                Open url in new tab:{pageUrl}
                            </span>
                            {pageUrl}
                        </a>
                    </p>);
            }

            return (
                <div className='text-center'>
                    <h1 className="h3"> {question['question']}</h1>
                    {extraInformation}
                    <ImagesContainer images={question['imagePaths']} />
                    <Row className='justify-content-center mb-5'>
                        <Col xs={6} className='d-flex justify-content-around'>
                            <Button className="btn btn-primary btn-lg manual-review-btn" onClick={this.handleYes}>Yes</Button>
                            <Button className="btn btn-primary btn-lg manual-review-btn" onClick={this.handleNo}>No</Button>
                        </Col>

                    </Row>


                    <ProgressBar
                        now={this.state.loadingBarProgress}
                        label=' '
                    />
                    <p className='text-center text-muted'>
                        <small>{`${Math.round(this.state.loadingBarProgress)}%`} complete</small>
                    </p>
                </div>
            );
        }
        else {
            return (
                <div className="text-center">
                    <Spinner animation="border" variant='primary' role="status" className='mt-5 mb-4'>
                        <span className="sr-only">Loading&hellip;</span>
                    </Spinner>
                    <p>Testing in Progress&hellip;</p>
                    <p className='text-muted'>This could take a few minutes</p>
                </div>
            );
        }

    }
}

ManualReviewPage.propTypes = {
    updateError: PropTypes.func,
    status: PropTypes.number,
    updates: PropTypes.func,
    match: PropTypes.object
};

export default ManualReviewPage;
