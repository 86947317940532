import React, {Component} from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import PropTypes from "prop-types";

class ImagesContainer extends Component {
    render() {
        if (!this.props.images || this.props.images.length === 0) { return null; }

        const oddNumberOfImages = this.props.images.length % 2 === 1;
        const imageDivs = this.props.images.map((imgsource, index) => {

            // If there is an odd number, render with the first image using the width of screen and the rest in rows of 2
            // If there is an even number of images render them in rows of 2
            const divClassName = (index === 0 && oddNumberOfImages) ? '' : "fb-50";

            const source = `data:image/png;base64, ${imgsource}`;

            return (
                <div className={divClassName} key={index}>
                    <Zoom>
                        <img className="img img-fluid mh-50 border border-dark d-block mx-auto" src={source} alt={"Screenshot of area of webpage providing context to the question"} />
                    </Zoom>
                </div>
            );
        });

        return (
            <div className="d-flex align-items-center justify-content-around h-100 p-4">
                {imageDivs}
            </div>
        );
    }
}

ImagesContainer.propTypes = {
    images: PropTypes.array
};

export default ImagesContainer;
