import React, { Component } from "react";
import Web2AccessCategory from "./Web2AccessCategory";
import categoryMap from "../../scripts/CategoryMap";
import PropTypes from "prop-types";

class Web2AccessCategories extends Component {
    render() {
        let items = [];
        for (let key in categoryMap) {
            items.push(
                <Web2AccessCategory
                    key={key}
                    categoryId={key}
                    result={this.props.results[key]}
                    lastUpdated={this.props.lastUpdated}
                    w2aRate={this.props.w2aRates[key]}
                />
            );
        }
        return items;
    }
}

Web2AccessCategories.propTypes = {
    results: PropTypes.object,
    lastUpdated: PropTypes.string,
    w2aRates: PropTypes.object
};

export default Web2AccessCategories;
