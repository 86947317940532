import React, {Component} from "react";
import PropTypes from "prop-types";

class ProblemComponent extends Component {
    render() {
        let problem = this.props.problem;
        let context;

        if (problem.context != null && problem.context !== '') {
            context = (
                <code className="context">{problem.context}</code>);
        } else {
            context = 'N/A';
        }
        return (
            <div className="bg-warning p-3 m-3 shadow-sm">
                <dl className='row mb-0'>
                    <dt className="col-sm-2">Page Name</dt>
                    <dd className='col-sm-10'>{problem.documentTitle}</dd>

                    <dt className="col-sm-2">Page URL</dt>
                    <dd className='col-sm-10'><a href={problem.pageUrl}>{problem.pageUrl}</a></dd>

                    <dt className="col-sm-2">Context</dt>
                    <dd className='col-sm-10 mb-0'>{context}</dd>
                </dl>
            </div>
        );
    }
}

ProblemComponent.propTypes = {
    problem: PropTypes.shape({
        documentTitle: PropTypes.string,
        pageUrl: PropTypes.string,
        context: PropTypes.string
    })
};

export default ProblemComponent;
