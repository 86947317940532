import React from 'react';
import LandingPage from '../pages/LandingPage';
import HelpPage from '../pages/HelpPage';
import DefaultError from '../pages/error/DefaultError';
import ReviewRouter from './ReviewRouter';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';

export default ({ childProps: cProps }) =>
    <Router>
        <Switch>
            <Route exact path='/' render={(props) => <LandingPage {...cProps} {...props} />} />
            <Route exact path='/help' render={(props) => <HelpPage {...cProps} {...props} />} />
            <Route exact path='/review/:review_id' render={(props) => <ReviewRouter {...cProps} {...props} />} />
            <Route component={() => <DefaultError title={"Not Found"} errorMessage={"The page you tried to visit was not found!"} />} />
        </Switch>
    </Router>;