import React, { Component } from 'react';
import { submitReview } from '../scripts/ApiCalls';
import {Button, Col, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import PropTypes from "prop-types";

//User enters URL - App redirects user to review/ReviewID_UUID
class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            testURL: '',
            errorMessage: null,
            errorCheck: false
        });
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = process.env.REACT_APP_SITE_TITLE;
    }

    handleChange({ target }) {
        this.setState({ testURL: target.value });
    }

    async handleSubmit(event) {
        console.log(event);
        event.preventDefault();

        let input = this.state.testURL;
        const response = await submitReview(input);

        if (!response.error) {
            this.setState({
                testURL: input,
                errorMessage: null,
                errorCheck: false
            });
            const review = response.data.review_id;
            let pathpage = `/review/${review}`;
            this.props.history.push(pathpage);
        }

        else {
            let note;
            if (response.data !== null && response.data.message !== null) {
                note = response.data.message;
            }
            else {
                note = "There was an issue communicating with the server. Try again later.";
            }
            this.setState(
                {
                    testURL: input,
                    errorMessage: note,
                    errorCheck: true
                },
                this.props.updateError(true, note)
            );
        }
    }

    render() {
        return (
            <>
                <Row className="justify-content-center text-center">
                    <Col sm={10} md={6}>
                        <h1 className='mb-4'>Accessibility Audit Tool<br/> and Statement Generator</h1>
                        <p className='lead'>Use this tool to automatically check the accessibility of a website, and generate a corresponding accessibility statement.</p>
                        <p className='lead mt-5'>To begin, enter the URL of the website you would like to test.</p>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group controlId='testUrl' className='text-left'>
                                <Form.Label className='h4'>Test Website URL</Form.Label>
                                <InputGroup>
                                    <FormControl
                                        placeholder='e.g. https://website.com'
                                        value={this.state.testURL}
                                        onChange={this.handleChange}
                                        required />
                                    <InputGroup.Append>
                                        <Button variant='primary' type="submit" value="Add URL">
                                            Begin <i className="fa fa-fw fa-chevron-right ml-1"/>
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }
}

LandingPage.propTypes = {
    updateError: PropTypes.func,
    history: PropTypes.array
};

export default LandingPage;

