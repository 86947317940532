import React, { Component } from "react";
import { Button, Overlay, Tooltip } from "react-bootstrap";
import { getWCAGUrl, getWCAGFormattedName } from "../../scripts/HelperFunctions";
import PropTypes from "prop-types";

class AccessibilityStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            urlList: null,
            aStatement: null,
            aaStatement: null,
            aaaStatement: null,
            nonAccessibleContent: "",
            nonCompliance: [],
            showCopyTooltip: false
        };
        this.setUrlList = this.setUrlList.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.showTooltip = this.showTooltip.bind(this);
        this.generateConformanceStatements = this.generateConformanceStatements.bind(this);
        this.target = React.createRef();
        this.accessibilityStatementRef = React.createRef();
    }

    handleClick() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    componentDidMount() {
        this.setUrlList();
        this.setConformanceStatements();
        this.nonCompliance();
    }

    setUrlList() {
        // Get list of URLs
        let urlList = [];
        let urls = this.props.statementData.urls;
        // Remove "bad" URLs from this list since they have not been evaluated so we should not claim so.
        let failedUrls = this.props.failedUrls;
        urls = urls.filter(function(url) {
            return !failedUrls.includes(url);
        });

        if (urls.length < 2) {
            // Don't need to state other URLs if there is only one.
            return;
        }

        for (let i = 1; i < urls.length - 1; i++) {
            let url = urls[i];
            urlList.push(
                <a href={url} contentEditable="false" suppressContentEditableWarning={true} key={i}>
                    {url}
                </a>
            );
            urlList.push(<span key={url+"1"}>,{" "}</span>);
        }

        if (urls.length > 2) {
            urlList.push(<span key={'and'}> and </span>);
        }

        let lastUrl = urls[urls.length - 1];
        urlList.push(
            <a href={lastUrl} key={lastUrl} contentEditable="false" suppressContentEditableWarning={true}>
                {lastUrl}
            </a>
        );
        let listElement = (
            <li>
                <div>
                    our other parts of the website, available at {urlList}
                </div>
            </li>
        );
        this.setState({
            urlList: listElement
        });

    }

    setConformanceStatements() {
        // Choose the correct statement for AA standard
        let A_rate = this.props.statementData.aRate;
        let AA_rate = this.props.statementData.aaRate;
        let AAA_rate = this.props.statementData.aaaRate;

        if (A_rate < 100) {
            this.generateConformanceStatements(A_rate, A_rate, A_rate);
        } else if (AA_rate < 100) {
            if (A_rate > AA_rate) {
                this.generateConformanceStatements(A_rate, AA_rate, AA_rate);
            } else {
                this.generateConformanceStatements(A_rate, A_rate, A_rate);
            }
        } else {
            this.generateConformanceStatements(A_rate, AA_rate, AAA_rate);
        }
    }

    generateConformanceStatements(A_rate, AA_rate, AAA_rate) {
        let aStatement = this.generateConformanceStatement(
            A_rate,
            "A"
        );
        let aaStatement = this.generateConformanceStatement(
            AA_rate,
            "AA"
        );
        let aaaStatement = this.generateConformanceStatement(
            AAA_rate,
            "AAA"
        );
        this.setState({
            aStatement: aStatement,
            aaStatement: aaStatement,
            aaaStatement: aaaStatement
        });
    }

    generateConformanceStatement(rate, conformanceLevel) {
        let statement;
        if (rate === 100) {
            statement = (
                <p>
                    This website is fully compliant with the{" "}
                    <a
                        rel="external"
                        href="https://www.w3.org/TR/WCAG21/"
                        contentEditable="false"
                        suppressContentEditableWarning={true}
                    >
                        Web Content Accessibility Guidelines version 2.1
                    </a>{" "}
                    {conformanceLevel} standard.
                </p>
            );
        } else if (rate > 50) {
            statement = (
                <p>
                    This website is partially compliant with the{" "}
                    <a
                        rel="external"
                        href="https://www.w3.org/TR/WCAG21/"
                        contentEditable="false"
                        suppressContentEditableWarning={true}
                    >
                        Web Content Accessibility Guidelines version 2.1
                    </a>{" "}
                    {conformanceLevel} standard, due to the non-compliances
                    listed below.
                </p>
            );
        } else {
            statement = (
                <p>
                    This website is not compliant with the{" "}
                    <a
                        rel="external"
                        href="https://www.w3.org/TR/WCAG21/"
                        contentEditable="false"
                        suppressContentEditableWarning={true}
                    >
                        Web Content Accessibility Guidelines version 2.1
                    </a>{" "}
                    {conformanceLevel} standard. The non-accessible sections are
                    listed below.
                </p>
            );
        }
        return statement;
    }

    nonCompliance() {
        let elements = [];
        let aaaFailures = this.props.statementData.aaaFailures;
        for (let i = 0; i < aaaFailures.length; i++) {
            let guideline = aaaFailures[i];
            elements.push(
                <p key={guideline}>
                    There exists a failure for WCAG 2.1 success criterion{" "}
                    <a href={getWCAGUrl(guideline)}
                        contentEditable="false"
                        suppressContentEditableWarning={true}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {guideline} - {getWCAGFormattedName(guideline)}
                    </a>.
                </p>
            );
            elements.push(
                <p key={guideline + '1'}>
                    We plan to{" "}
                    <em>
                        <strong>
                        [resolution to above issue]
                        </strong>
                    </em>{" "}
                    by September 2020. We will make sure that we meet the
                    accessibility standards for the WCAG guideline{" "}
                    <a href={getWCAGUrl(guideline)}
                        contentEditable="false"
                        suppressContentEditableWarning={true}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {guideline} - {getWCAGFormattedName(guideline)}
                    </a>.
                </p>
            );
        }
        this.setState({
            nonCompliance: elements
        });
    }

    copyToClipboard() {

        const element = this.accessibilityStatementRef.current.innerHTML;

        function listener(e) {
            e.clipboardData.setData("text/html", element);
            e.clipboardData.setData("text/plain", element);
            e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
        this.showTooltip();
    }

    showTooltip() {
        this.setState({
            showCopyTooltip: true
        });

        setTimeout(function() {
                this.setState({
                    showCopyTooltip: false
                });
            }.bind(this),
            1000
        );
    }

    render() {
        
        const instructionClass = 'p-3 m-3 bg-light border border-secondary';
        
        return (
            <div className="mb-3">
                <div>
                        <div className="text-center mt-3">
                            <Button
                                ref={this.target}
                                onClick={this.copyToClipboard}
                            >Copy Statement HTML to Clipboard</Button>
                            <Overlay
                            target={this.target.current}
                            show={this.state.showCopyTooltip}
                            placement="top"
                            >
                                <Tooltip id='copied-tooltip'>
                                    Copied!
                                </Tooltip>
                            </Overlay>
                        </div>
                        <p className={instructionClass}>
                            This part of the page is <em><strong>editable</strong></em> so feel free to edit the statement.
                            It <em><strong>will not be saved</strong></em>, so be sure to take a copy.
                        </p>

                        <div className="p-4 border rounded-lg">
                            <div
                                contentEditable="true"
                                suppressContentEditableWarning={true}
                                ref={this.accessibilityStatementRef}
                            >
                                <h1 id="accessibility-statement-for-website-name">
                                    Accessibility statement for{" "}
                                    <a
                                        href={this.props.url}
                                        contentEditable="false"
                                        suppressContentEditableWarning={true}
                                    >
                                        {this.props.url}
                                    </a>
                                </h1>

                                <div className={instructionClass}>
                                    <em><strong>
                                        <p>[Note: start with a brief explanation of which websites or mobile apps the statement covers.</p>
                                        <p>You can have a single accessibility statement that covers multiple domains, or a separate statement for each domain or subdomain. As long as the user can access relevant accessibility information easily from any page on your website.]</p>
                                    </strong></em>
                                </div>

                                <p>This accessibility statement applies to [scope of statement, e.g. website or domain to which the statement applies].</p>

                                <div>
                                    <p className={instructionClass}>
                                    <em><strong>
                                        [Note: use this section to make a brief, general statement about what the website allows disabled users to do. Base it on the evaluation covered in detail in the ‘Technical information about this website’s accessibility’ section. If you’re not confident that something is accurate, leave it out. If you’re not confident enough to say anything specific here, leave this section out completely.]
                                    </strong></em>
                                    </p>
                                </div>

                                <p>
                                    This website is run by{" "}
                                    <em>
                                        <strong>
                                        [name of organisation].
                                        </strong>
                                    </em>{" "}
                                    We want as many people as possible to be
                                    able to use this website. For example, that
                                    means you should be able to:
                                </p>

                                <ul>
                                    <li>
                                        change colours, contrast levels and
                                        fonts
                                    </li>
                                    <li>
                                        zoom in up to 300% without the text
                                        spilling off the screen
                                    </li>
                                    <li>
                                        navigate most of the website using just
                                        a keyboard
                                    </li>
                                    <li>
                                        navigate most of the website using
                                        speech recognition software
                                    </li>
                                    <li>
                                        listen to most of the website using a
                                        screen reader (including the most recent
                                        versions of JAWS, NVDA and VoiceOver)
                                    </li>
                                </ul>

                                <p>
                                    We’ve also made the website text as simple
                                    as possible to understand.
                                </p>

                                <p>
                                    <a
                                        rel="external"
                                        href="https://mcmw.abilitynet.org.uk/"
                                        contentEditable="false"
                                        suppressContentEditableWarning={true}
                                    >
                                        AbilityNet
                                    </a>{" "}
                                    has advice on making your device easier to
                                    use if you have a disability.
                                </p>

                                <h2 id="how-accessible-this-website-is">
                                    How accessible this website is
                                </h2>

                                <div>
                                    <p className={instructionClass}>
                                        <em><strong>
                                        [Note: use this section to provide information that a disabled user can act on - for example, avoid a particular section of the website, or request an alternative version rather than waste time trying to make it work with their assistive technology. Try to list in order of most impact to least impact.]
                                        </strong></em>
                                    </p>
                                    <p>We know some parts of this website are not fully accessible:</p>
                                    <ul>
                                        <li>the text will not reflow in a single column when you change the size of the browser window</li>
                                        <li>you cannot modify the line height or spacing of text</li>
                                        <li>most older PDF documents are not fully accessible to screen reader software</li>
                                        <li>live video streams do not have captions
                                                some of our online forms are difficult to navigate using just a keyboard</li>
                                        <li>you cannot skip to the main content when using a screen reader</li>
                                        <li>there’s a limit to how far you can magnify the map on our ‘contact us’ page</li>
                                    </ul>
                                </div>

                                <h2 id="what-to-do-if-you-cannot-access-parts-of-this-website">
                                    Feedback and contact information
                                </h2>

                                <p>
                                    If you need information on this website in a different format like accessible PDF, large print, easy read, audio recording or braille:
                                </p>

                                <ul>
                                    <li>
                                        email:{" "}
                                        <em>
                                            <strong>
                                            [email address]
                                            </strong>
                                        </em>
                                    </li>
                                    <li>
                                        call:{" "}
                                        <em>
                                            <strong>
                                            [phone number]
                                            </strong>
                                        </em>
                                    </li>
                                    <li>
                                        <em>
                                            <strong>
                                                [add any other contact details]
                                            </strong>
                                        </em>
                                    </li>
                                </ul>

                                <p>
                                    We’ll consider your request and get back to
                                    you in{" "}
                                    <em>
                                        <strong>
                                        [number]
                                        </strong>
                                    </em>{" "}
                                    days.
                                </p>

                                <h2 id="reporting-accessibility-problems-with-this-website">
                                    Reporting accessibility problems with this website
                                </h2>

                                <p>
                                    We’re always looking to improve the accessibility of this website. If you find any problems not listed on this page or think we’re not meeting accessibility requirements, contact: {" "}
                                    <em>
                                        <strong>
                                            [provide both details of how to report these issues to your organisation, and contact details for the unit or person responsible for dealing with these reports].
                                        </strong>
                                    </em>
                                </p>

                                <h2 id="enforcement-procedure">
                                    Enforcement procedure
                                </h2>

                                <p>
                                    The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’). If you’re not happy with how we respond to your complaint,{" "}
                                    <a
                                        rel="external"
                                        href="https://www.equalityadvisoryservice.com/"
                                        contentEditable="false"
                                        suppressContentEditableWarning={true}
                                    >
                                        contact the Equality Advisory and Support Service (EASS)
                                    </a>
                                    .
                                </p>

                                <div>
                                    <p className={instructionClass}>
                                        <em><strong>
                                            [Note: if your organisation is based in Northern Ireland, refer users who want to complain to the Equalities Commission for Northern Ireland (ECNI) instead of the EASS and EHRC.]
                                        </strong></em>
                                    </p>
                                </div>

                                <h2 id="enforcement-procedure">
                                    Contacting us by phone or visiting us in person
                                </h2>

                                <p>We provide a text relay service for people who are D/deaf, hearing impaired or have a speech impediment.</p>

                                <p>Our offices have audio induction loops, or if you contact us before your visit we can arrange a British Sign Language (BSL) interpreter.</p>

                                <p>Find out how to contact us <em><strong>[add link to contact details page]</strong></em>.</p>

                                <h2 id="technical-information-about-this-websites-accessibility">
                                    Technical information about this website’s accessibility
                                </h2>

                                <div>
                                    <p className={instructionClass}>
                                        <em><strong>
                                        [Note: this form of wording is legally required, so do not change it.]
                                        </strong></em>
                                    </p>
                                </div>

                                <p>
                                    <em>
                                        <strong>
                                        [Name of organisation]
                                        </strong>
                                    </em>{" "}
                                    is committed to making its website accessible, in accordance with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.
                                </p>

                                <h3>Compliance Status</h3>
                                <div className={instructionClass}>
                                    <em><strong>
                                        <p>[Note: say that the website is fully compliant if the website meets WCAG 2.1 AA standard in full. Say that it’s partially compliant if it meets most requirements of the WCAG 2.1 AA standard. If it does not meet most requirements of the WCAG 2.1 AA standard, say that it’s not compliant.</p>
                                        <p>If your website is either partially compliant or not compliant WCAG 2.1 AA standard, you’ll need to explain why. This will be due to one or both of the following:</p>
                                        <ul>
                                            <li>non-compliances - this means the content in question is in scope of the regulations, but there’s an accessibility problem with it</li>
                                            <li>an exemption - this means the inaccessible content is out of scope of the regulations, or it’d be a disproportionate burden for you to make it accessible</li>
                                        </ul>
                                        <p>There’s a legally required way of expressing the compliance status of your website, so do not change it.]</p>
                                    </strong></em>
                                </div>

                                {this.state.aStatement}
                                {this.state.aaStatement}
                                {this.state.aaaStatement}
                                <div>

                                <h2 id="non-accessible-content">Non accessible content</h2>
                                <div className={instructionClass}>
                                    <em><strong>
                                        <p>[Note: if the website is fully compliant with the WCAG 2.1 AA standard, you can leave the ‘Non-accessible content’ section out.
                                        </p>

                                    <p>Otherwise, do not change the ‘Non-accessible content’ heading or the ‘The content listed below is non-accessible for the following reasons’ sentence - they’re legally required.</p>

                                    <p>Do not change the ‘Non-compliance with the accessibility regulations’, ‘Disproportionate burden’ and ‘Content that’s not within the scope of the accessibility regulations’ subheadings: they’re also legally required.</p>

                                    <p>But if you need to list a lot of problems, you can break these subsections up with further subheadings - for example, ‘Navigation and accessing information’ or ‘Interactive tools and transactions’.]</p>
                                    </strong></em>
                                </div>

                                <p>The content listed below is non-accessible for the following reasons.</p>

                                <p>
                                    <em><strong>
                                    [Explain the reasons for the failures in the below section
                                    (if there are any).]
                                    </strong></em>
                                </p>

                            </div>

                                <h2 id="non-compliance-with-the-accessibility-regulations">
                                    Non compliance with the accessibility regulations
                                </h2>

                                <div className={instructionClass}>
                                    <em><strong>
                                        <p>[Note: In this subsection, list:</p>
                                        <ul>
                                            <li>accessibility problems</li>
                                            <li>which of the WCAG 2.1 AA success criteria the problem fails on</li>
                                            <li>when you plan to fix the problem</li>
                                        </ul>
                                        <p>Do not include any problems where you’re claiming disproportionate burden, or where the problem is outside the scope of the accessibility regulations (those should go in the subsections below).]</p>
                                    </strong></em>
                                </div>

                                {this.state.nonCompliance}

                                <h2 id="disproportionate-burden">
                                    Disproportionate burden
                                </h2>

                                <div className={instructionClass}>
                                    <em><strong>
                                        {/* This bit can't be automated. It's up the user
                                            what is a disproporionate budren */}
                                        <p>[Note: in this subsection list accessibility problems you’re claiming would be a disproportionate burden to fix</p>

                                        <p>Bear in mind that something which is a disproportionate burden now will not necessarily be a disproportionate burden forever. If the circumstances change, your ability to claim disproportionate burden may change too.]</p>
                                    </strong></em>
                                </div>

                                <h3 id="navigation-and-accessing-information">
                                    Navigation and accessing information
                                </h3>

                                <div className={instructionClass}>
                                    <em><strong>
                                        <p>[Fill in any issues with navigation and accessing information on the website that are a disproportionate burden. For instance:</p>
                                        <ul>
                                            <li>There’s no way to skip the repeated content in the page header (for example, a ‘skip to main content’ option).</li>
                                            <li>It’s not always possible to change the device orientation from horizontal to vertical without making it more difficult to view the content.</li>
                                            <li>It’s not possible for users to change text size without some of the content overlapping.]</li>
                                        </ul>
                                    </strong></em>
                                </div>

                                <h3 id="interactive-tools-and-transactions">
                                    Interactive tools and transactions
                                </h3>

                                <div className={instructionClass}>
                                    <p>
                                        <em><strong>
                                        [Fill in any issues with interactive tools and transactions, if there are any. For instance:]
                                        </strong></em>
                                    </p>
                                    <ul>
                                        <li>Some of our interactive forms are difficult to navigate using a keyboard. For example, because some form controls are missing a ‘label’ tag.</li>
                                        <li>Our forms are built and hosted through third party software and ‘skinned’ to look like our website.</li>
                                    </ul>

                                    <p>We’ve assessed the cost of fixing the issues with navigation and accessing information, and with interactive tools and transactions. We believe that doing so now would be a <a href="http://www.legislation.gov.uk/uksi/2018/952/regulation/7/made">disproportionate burden</a> within the meaning of the accessibility regulations. We will make another assessment when the supplier contract is up for renewal, likely to be in [rough timing].</p>
                                </div>

                                <h2 id="content-thats-not-within-the-scope-of-the-accessibility-regulations">Content that’s not within the scope of the accessibility regulations</h2>

                                <div className={instructionClass}>
                                    <em><strong>
                                        <p>[Note: in this subsection list accessibility problems that fall outside the scope of the accessibility regulations. For instance:</p>

                                        <h3>PDFs and other documents</h3>
                                        <p>Some of our PDFs and Word documents are essential to providing our services. For example, we have PDFs with information on how users can access our services, and forms published as Word documents. By September 2020, we plan to either fix these or replace them with accessible HTML pages.</p>

                                        <p>The accessibility regulations <a href="http://www.legislation.gov.uk/uksi/2018/952/regulation/4/made">do not require us to fix PDFs or other documents published before 23 September 2018</a> if they’re not essential to providing our services. For example, we do not plan to fix [example of non-essential document].</p>

                                        <p>Any new PDFs or Word documents we publish will meet accessibility standards.</p>

                                        <h3>Live video</h3>
                                        <p>We do not plan to add captions to live video streams because live video is <a href="http://www.legislation.gov.uk/uksi/2018/952/regulation/4/made">exempt from meeting the accessibility regulations</a>.</p>

                                    </strong></em>

                                </div>

                                <h2>What we’re doing to improve accessibility</h2>
                                <p className={instructionClass}>
                                    [Note: publishing an accessibility roadmap is optional. It’s a good idea to publish one if you want to be specific about the order you’re planning to tackle accessibility issues, and there’s no space to do so in the accessibility statement itself.]
                                </p>

                                <p>Our accessibility roadmap [add link to roadmap] shows how and when we plan to improve accessibility on this website.</p>


                                <h2>Preparation of this accessibility statement</h2>
                                <p className={instructionClass}>[Note: the wording about when the statement was prepared is legally required, so do not change it.]</p>
                                <p>This statement was prepared on [date when it was first published]. It was last reviewed on [date when it was last reviewed].</p>
                                <p>This website was last tested on [date]. The test was carried out by [add name of organisation that carried out test, or indicate that you did your own testing].</p>
                                <p>We used this approach to deciding on a sample of pages to test [add link to explanation of how you decided which pages to test].</p>

                                <p className={instructionClass}>[Note: you do not have to use this approach to sampling, but you should link to a full explanation of what you tested and how you chose it. If you get a third party auditor to test your website for you, they should include sampling details in test report - so you can just to link to that.]</p>

                                <p>
                                    You can read the full accessibility test
                                    report at:{" "}
                                    <a
                                        href={window.location.href}
                                        contentEditable="false"
                                        suppressContentEditableWarning={true}
                                    >
                                        <em><strong>
                                            {window.location.href}
                                        </strong></em>
                                    </a>
                                </p>

                                <p className={instructionClass}>[Note: publishing the test report is optional, but doing so may allow you to make your accessibility statement shorter and more focused.]</p>

                                <p>We tested:</p>
                                <ul>
                                    <li>
                                        our main website/platform, available at{" "}
                                        <a
                                            href={this.props.url}
                                            contentEditable="false"
                                            suppressContentEditableWarning={true}
                                        >
                                            {this.props.url}
                                        </a>
                                    </li>
                                    {this.state.urlList}
                                </ul>

                                <h2 id="interesting-results">
                                    Automated Testing Statistics
                                </h2>

                                <p className={instructionClass}>
                                    <em><strong>
                                    [Note: these statistics are based on the number of guidelines of each conformance level that have failing tests rather than the total number of tests since each guideline has multiple tests. A conformance level A failure is more severe than a level AAA failure but the number of tests for each conformance level is not equal.]
                                    </strong></em>
                                </p>
                                <ul>
                                    <li>
                                        Conformance level A guideline pass rate: {this.props.statementData.aRate.toFixed(2)}%
                                    </li>
                                    <li>
                                        Conformance level AA guideline pass rate: {this.props.statementData.aaRate.toFixed(2)}%
                                    </li>
                                    <li>
                                        Conformance level AAA guideline pass rate: {this.props.statementData.aaaRate.toFixed(2)}%
                                    </li>
                                </ul>

                                <p>
                                    <small>
                                    This accessibility statement was automatically generated and was created using the <a
                                        contentEditable="false"
                                        suppressContentEditableWarning={true}
                                        href="https://www.gov.uk/government/publications/sample-accessibility-statement/sample-accessibility-statement-for-a-fictional-public-sector-website"
                                    >sample format defined by the UK Government (2 April 2020 edition)</a>
                                    . The user of this system should ensure that the statement is accurate and fill in the required sections. We are not responsible for the accuracy of the accessibility statement.
                                    </small>
                                </p>
                            </div>
                        </div>
                </div>
            </div>
        );
    }
}

AccessibilityStatement.propTypes = {
    failedUrls: PropTypes.arrayOf(PropTypes.string),
    statementData: PropTypes.shape({
        successRate: PropTypes.number,
        aRate: PropTypes.number,
        aaRate: PropTypes.number,
        aaaRate: PropTypes.number,
        aaaFailures: PropTypes.array,
        urls: PropTypes.arrayOf(PropTypes.string)
    }),
    url: PropTypes.string,
    lastUpdated: PropTypes.string
};

export default AccessibilityStatement;
