import React, { Component } from "react";
import CustomRouter from "./components/CustomRouter.js";
import "./App.scss";
import {Navbar, Nav} from "react-bootstrap";
import ErrorToast from "./components/ErrorComponents/ErrorToast";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorMsg: null
        };
        this.updateError = this.updateError.bind(this);
    }

    updateError(hasError, errorMsg) {
        this.setState({
            hasError: hasError,
            errorMsg: errorMsg
        });
    }

    render() {
        let year = new Date().getFullYear();
        return (
            <div>
                <Navbar id="navbar" bg='primary' variant='dark'>
                    <div className='container-xl'>
                        <Navbar.Brand>Web2Access Audit Tool</Navbar.Brand>
                        <Nav className="ml-auto">
                            <Nav.Link href="/help" target='_blank'>Help</Nav.Link>
                        </Nav>
                    </div>
                </Navbar>
                <ErrorToast hasError={this.state.hasError} message={this.state.errorMsg} updateError={this.updateError} />
                <div className='container-xl mt-4'>
                    <CustomRouter childProps={{updateError: this.updateError}} />
                </div>
                <footer role="contentinfo" className='bg-primary text-white'>
                    <div className="container text-sm-center mt-4 py-4">
                        <div className="mb-2">&copy; {year} University of Southampton</div>
                    <small>
                        <a className='text-white' target='_blank' rel='noopener noreferrer' href='https://access.ecs.soton.ac.uk/privacy-policy'>
                            Privacy Policy
                        </a>
                        &nbsp;&middot;&nbsp;
                        <a className='text-white' target='_blank' rel='noopener noreferrer' href='https://access.ecs.soton.ac.uk/accessibility'>
                            Accessibility Statement
                        </a>
                    </small>
                    </div>
                </footer>
            </div>
        );
    }
}

export default App;
