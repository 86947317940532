import React from "react";
import Toast from "react-bootstrap/Toast";
import PropTypes from "prop-types";

function ErrorToast(props) {
    const closeError = () =>{
        props.updateError(false, null);
    };

    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            style={{
                position: 'fixed',
                top: '1em',
                right: '1em',
                minWidth: '15em',
                zIndex: '10',
            }}>
            <Toast
                show={props.hasError}
                onClose={closeError}
                autohide="true"
                animation="true"
                delay="5000"
                role="alert"
                aria-live="assertive"
            >
                <Toast.Header>
                    <strong className="mr-auto">Error</strong>
                </Toast.Header>
                <Toast.Body>{props.message}</Toast.Body>
            </Toast>
        </div>
    );
}

ErrorToast.propTypes = {
    hasError: PropTypes.bool,
    message: PropTypes.string,
    updateError: PropTypes.func
};

export default ErrorToast;
