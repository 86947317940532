import React, { Component } from 'react';
import {getStatus} from '../scripts/ApiCalls.js';
import DefaultError from '../pages/error/DefaultError';
import ReviewPage from '../pages/ReviewPage';
import ResultsPage from '../pages/ResultsPage';
import ManualReviewPage from "../pages/ManualReviewPage";
import {Spinner} from "react-bootstrap";
import {getReview} from "../scripts/ApiCalls";

const SUBMITTED_STATUS = 0;
const STARTED_PENDING_MANUAL_REVIEW_INPUT = 1;
const STARTED_PENDING_MANUAL_REVIEW_USER = 2;
const STARTED_PENDING_AUTOMATIC_RESULTS = 3;
const STARTED_PENDING_STATEMENT = 4;
const COMPLETE_STATUS = 5;
const ERROR_STATUS = 99;


class ReviewRouter extends Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false, status: null };
        this.checkStatus = this.checkStatus.bind(this);
    }

    async componentDidMount() {
        await this.checkStatus();
    }

    async checkStatus() {
        const review_id = this.props.match.params.review_id;
        let statusRes;
        let reviewRes;
        if(review_id){
            statusRes = await getStatus(review_id);
            reviewRes = await getReview(review_id);
        }
        if(statusRes && review_id && !statusRes.error){
            this.setState({loaded:true, status: statusRes.data.review_status, review: reviewRes.data});
        } else {
            if(statusRes && statusRes.status === 404){
                this.setState({loaded:true, status: "Not Found"});
            } else {
                this.setState({loaded:true, status: null });
            }
        }
    }

    render() {
        if(this.state.loaded){
            let status = this.state.status;
            if(status === SUBMITTED_STATUS){
                return(<ReviewPage status={SUBMITTED_STATUS} updates={this.checkStatus} {...this.props}/>);
            } else if( status === STARTED_PENDING_MANUAL_REVIEW_INPUT){
                return(<ManualReviewPage status={STARTED_PENDING_MANUAL_REVIEW_INPUT} updates={this.checkStatus} {...this.props}/>);
            } else if( status === STARTED_PENDING_MANUAL_REVIEW_USER) {
                return(<ManualReviewPage status={STARTED_PENDING_MANUAL_REVIEW_USER} updates={this.checkStatus} {...this.props}/>);
            } else if( status === STARTED_PENDING_AUTOMATIC_RESULTS) {
                return(<ResultsPage status={STARTED_PENDING_AUTOMATIC_RESULTS} updates={this.checkStatus} {...this.props}/>);
            } else if( status === STARTED_PENDING_STATEMENT) {
                return(<ResultsPage status={STARTED_PENDING_STATEMENT} updates={this.checkStatus} {...this.props}/>);
            } else if( status === COMPLETE_STATUS) {
                return(<ResultsPage status={COMPLETE_STATUS} updates={this.checkStatus} {...this.props}/>);
            } else if( status === "Not Found" ) {
                return(<DefaultError status={null} title={"Review Not Found"} errorMessage={"We could not find this review."} updates={this.checkStatus} {...this.props}/>);
            } else if ( status == null ) {
                return(<DefaultError status={null} title={"Error"} errorMessage={"There was an error checking the status of this review in the backend. Please check the url and your internet connection and refresh the page."} updates={this.checkStatus} {...this.props}/>);
            } else if ( status === ERROR_STATUS) {
                return(<DefaultError status={ERROR_STATUS} title={"Error performing this review"} errorMessage={"There was an error reviewing this site. Please try to create a review for this site again."} updates={this.checkStatus} {...this.props}/>);
            }
        } else {
            return(
                <div className='text-center'>
                    <Spinner animation="border" variant='primary' role="status" className='mt-5 mb-4'>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    <p>Loading&hellip;</p>
                </div>
            );
        }
    }
}

export default ReviewRouter;
