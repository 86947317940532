import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            isChecked: props.isChecked
        });
        this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
    }

    toggleCheckboxChange() {
        const label = this.props.label;
        this.props.handleChange(!this.state.isChecked, label);
        this.setState({ isChecked: !this.state.isChecked });
    }

    render() {
        const label = this.props.label;
        const isChecked = this.state.isChecked;

        return (
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id={label} checked={isChecked} onChange={this.toggleCheckboxChange} />
                <label className="form-check-label" htmlFor={label}>
                    {label}
                </label>
                <a title={`Open ${label} in a new tab`} href={label} rel="noopener noreferrer" target="_blank">
                    <span className="sr-only">Open url in new tab: {label}</span>
                    <i className="fa fa-external-link ml-2" aria-hidden="true" />
                </a>
            </div>
        );
    }
}

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool
};

export default Checkbox;
