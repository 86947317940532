import React, { Component } from "react";
import PropTypes from "prop-types";

class EvaluationItem extends Component {
    render() {
        if (
            this.props.result === undefined ||
            Object.keys(this.props.result).length === 0
        ) {
            return 'No issues';
        }
        return null
    }
}

EvaluationItem.propTypes = {
    result: PropTypes.array,
    w2aRate: PropTypes.number
};

export default EvaluationItem;
