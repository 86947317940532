const url = process.env.REACT_APP_BACKEND_ADDRESS;

function getHeaders(){
    return {'Content-Type': 'application/json'};
}

/**
 * Helper function to perform an api call to url/address/ with the given data.
 * Has error handling and will catch any exceptions and return an object with error:True on error.
 *
 * @param {String} address   the suffix to be added to the base url, e.g. /report/submit
 * @param {JSON} data      the data to be give'n to fetch, e.g. {method:'GET'}
 *
 * @return              A json object containing error: {Bool} and if possible status: {int} and data: {json object}
 */
async function callApi(address, data) {

    let response, responseContent;
    try {
        response = await fetch(url + address, data);
        responseContent = await response.json();

        return {
            status: response.status,
            data: responseContent,
            error: !responseContent.success,
        };
    } catch (e) {
        console.error(e);
        return {
            status: response ? response.status : null,
            data: responseContent  || null,
            error: true,
        };
    }
}

/**
 * Performs an API call. Does not wrap content in data attr on succcess.
 *
 * @param {String} address   the suffix to be added to the base url, e.g. /report/submit
 * @param {JSON} data      the data to be give'n to fetch, e.g. {method:'GET'}
 *
 * @return              A json object containing error: {Bool} and if possible status: {int} and data: {json object}
 */
async function callApi2(address, data) {

    let response, responseContent;
    try {
        response = await fetch(url + address, data);
        return await response.json();
    } catch (e) {
        console.error(e);
        return {
            status: response ? response.status : null,
            data: responseContent  || null,
            error: true,
        };
    }
}

/**
 * Get a Review
 *
 * @returns {JSON}  Contains api response from the getStatus call.
 *
 * Status 1 means review has been submitted but not started.
 * Status 2 means review has been started and is pending results.
 * Status 3 means review has results.
 * @param reviewId                  The Review to retrieve
 * @param includeManualReviewData
 */
export async function getReview(reviewId, includeManualReviewData = false) {
    const address = `/review/${reviewId}/?manual_review_page_input=${includeManualReviewData}`;
    let review = await callApi2(address, {
        'method': 'GET',
        'headers': getHeaders(),
    });
    review.last_updated = Date.parse(review.last_updated);
    return review;
}

/**
 * Send a Review to Web2Access
 *
 * @returns {JSON}  Contains api response from the getStatus call.
 *
 * @param review Review                  The Review to send
 */
export async function sendReviewToWeb2Access(review) {

    const address = `/review/${review.id}/send_to_web2access`;
    return await callApi2(address, {
        'method': 'POST',
        'headers': getHeaders(),
    });
}

/**
 * Helper function to call the /review/status api
 *
 * @param {String}  review_id of the review to get status of.
 * @returns {JSON}  Contains api response from the getStatus call.
 *
 * Status 1 means review has been submitted but not started.
 * Status 2 means review has been started and is pending results.
 * Status 3 means review has results.
 */
export async function getStatus(review_id){
    const data = {
        'method': 'GET',
        'headers': getHeaders(),
    };
    const address = `/review/${review_id}/status?time=${new Date().getTime()}`;
    return await callApi(address, data);
}

/**
 * Helper function to call the /review/statement api
 *
 * @param {String}  review_id of the review to get statement of.
 * @returns {JSON}  Contains response from the /review/statement api call.
 */
export async function getStatement(review_id){
    const data = {
        'method': 'GET',
        'headers': getHeaders(),
    };
    const address = `/review/${review_id}/statement?time=${new Date().getTime()}`;
    return await callApi(address, data);
}

/**
 * Helper function to call the /review/results api
 *
 * @param {String}  review_id of the review to get results of.
 * @returns {JSON}  Contains response from the /review/results api call.
 */
export async function getResults(review_id){
    const data = {
        'method': 'GET',
        'headers': getHeaders(),
    };
    const address = `/review/${review_id}/results?time=${new Date().getTime()}`;
    return await callApi(address, data);
}

/**
 * Creates a new Review
 * POST /reviews
 *
 * @param {String}  url The url of the website for the review.
 * @returns {JSON}  Contains response from the /review/submit api call.
 */
export async function submitReview(url){
    const body = JSON.stringify({
        'url': url,
    });
    const data = {
        'method': 'POST',
        'headers': getHeaders(),
        'body': body,
    };
    const address = `/review?time=${new Date().getTime()}`;
    return await callApi(address, data);
}

/**
 * Helper function to call the /review/start api
 *
 * @param {String}      review_id The review ID of the review to be started.
 * @param {String[]}    urls Array of urls to be reviewed.
 * @returns {JSON}      Contains response from the /review/start api call.
 */
export async function startReview(review_id, urls){
    const body = JSON.stringify({
        'urls': urls
    });
    const data = {
        'method': 'POST',
        'headers': getHeaders(),
        'body': body,
    };
    const address = `/review/${review_id}/start?time=${new Date().getTime()}`;
    return await callApi(address, data);
}

/**
 * Helper function to call the /review/url API.
 * Get the top-level review URL for a review given a review_id.
 * @param {String} review_id The review ID of the review to be started.
 */
export async function getUrl(review_id){
    const data = {
        'method': 'GET'
    };
    const address = `/review/${review_id}/url`;
    return await callApi(address, data);
}

/**
 * Helper function to call the /crawl api to find valid urls on the given website
 *
 * @param {String}  url The url to crawl and find urls on
 * @param {int}     depth The number of pages to crawl.
 * @returns {JSON}  Contains response from the /crawl api call.
 */
export async function crawlSite(url, depth){
    const data = {
        'method': 'GET',
        'headers': getHeaders(),
    };
    const address = `/crawl?depth=${depth}&url=${url}`;
    return await callApi(address, data);
}

/**
 * Helper function to call the /review/last_updated API.
 * Return the datetime at which the review was last updated.
 * @param {String} review_id The review ID of the review to be started.
 */
export async function getLastUpdated(review_id){
    const data = {
        'method': 'GET',
        'headers': getHeaders(),
    };
    const address = `/review/${review_id}/last_updated?time=${new Date().getTime()}`;
    return await callApi(address, data);
}

/**
 * Helper function to call the /review/manual_results api
 *
 * @param {String}  review_id of the review to get manual results of.
 * @returns {JSON}  Contains response from the /review/manual_results api call.
 */
export async function getManualResults(review_id) {
    const data = {
        'method': 'GET',
        'headers': getHeaders()
    };
    const address = `/review/${review_id}/manual_results?time=${new Date().getTime()}`;
    return await callApi(address, data);
}

/**
 * POSTs the results of all manual tests to the API.
 *
 * @param {String}      review_id of the review to post the manual results for
 * @param {String}        manualResults The JSON manual results.
 * @returns {JSON}      Contains response from the /review/manual_review api call.
 */
export async function postManualReview(review_id, manualResults){
    const data = {
        'method': 'POST',
        'headers': getHeaders(),
        'body': manualResults
    };
    const address = `/review/${review_id}/manual_review?time=${new Date().getTime()}`;
    return await callApi(address, data);
}

/**
 *
 *
 * @param {String}  review_id of the review to get manual_input of.
 * @returns {JSON}  Contains response from the /review/manual_input api call.
 */
export async function getManualInput(review_id){
    const data = {
        'method': 'GET',
        'headers': getHeaders(),
    };
    const address = `/review/${review_id}/manual_input?review_id=time=${new Date().getTime()}`;
    return await callApi(address, data);
}
