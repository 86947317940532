import {guidelineUrlMappings} from './WcagUrls';

/*
 * Returns the guideline if it fits the right format, otherwise
 * returns false.
 */
export function findGuideline(code) {
    try{
        // Code is of format like WCAG2AAA.Principle1.Guideline1_4.1_4_6.G17.Fail
        const results = /(?:.*\.){3}(\d+)_(\d+)_(\d+)(?:_AAA)?(?:\..*){1,2}/.exec(code);
        return `${results[1]}.${results[2]}.${results[3]}`;
    } catch (err){
        // Could be that there is no technique (i.e. text spacing or orientation)
        try {
            const results = /(?:.*\.){3}(\d+)_(\d+)_(\d+)/.exec(code);
            return `${results[1]}.${results[2]}.${results[3]}`;
        } catch(err) {
            console.error(`Error in findguideline for code ${code}`);
            return false;
        }
    }
}

/*
 * Returns the url to the wcag page for the given x.y.z guideline, or false is nonexistant guideline
 */
export function getWCAGUrl(guideline) {
    const baseUrl = 'https://www.w3.org/WAI/WCAG21/Understanding/';
    const path = getWCAGName(guideline);
    if(path) {
        return baseUrl+path;
    }
    return false;
}

/*
 * Returns the name of the wcag page for the given x.y.z guideline
 */
export function getWCAGName(guideline) {
    const name = guidelineUrlMappings[guideline];
    return name ? name : false;
}

/*
 * Returns the name of the wcag page for the given x.y.z guideline, formatted to be upper-case and without dashes
 */
export function getWCAGFormattedName(guideline) {
    const name = getWCAGName(guideline);
    const formattedName = name.replace(/-/g," ").replace(/^./, ch => ch.toUpperCase());
    return formattedName ? formattedName : false;
}

/*
 * Calculates the total number of questions for the manual review
 */
export function calculateTotalQuestions(json) {
    let totalQuestions = 0;
    console.log(json);
    if (json.length) {
        for (let i = 0; i < json.length; i ++) {
            console.log(json[i]['questions']);
            totalQuestions += json[i]['questions'].length;
        }
        return totalQuestions;
    }
    return 0;
}

/*
 * Finds how many images should be skipped.
 */
export function skipQuestionsForPage(json, url, currentGuideline, currentQuestion) {
    let toSkip = 0;
    for (let i = currentQuestion; i < json[currentGuideline]['questions'].length; i++) {
        if (json[currentGuideline]['questions'][i]['pageUrl'] === url) {
            toSkip++;
        } else break;
    }
    return toSkip;
}
