import React, { Component } from "react";
import PropTypes from "prop-types";

class DefaultError extends Component {

    componentDidMount() {
        document.title = `Error: ${this.props.title}`;
    }

    render() {
        return (
            <div className="text-center">
                <h1>{this.props.title}</h1>
                <p>{this.props.errorMessage}</p>
                <i className="fa fa-frown-o fa-3x" aria-hidden="true"/>
            </div>
        );
    }
}

DefaultError.propTypes = {
    title: PropTypes.string,
    errorMessage: PropTypes.string
};

export default DefaultError;
