import React, { Component } from "react";
import {Button, Card, Collapse} from "react-bootstrap";
import PropTypes from "prop-types";

class FailedUrlsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        if (this.props.failedUrls.length === 0) return null;

        const failedUrls = this.props.failedUrls.map((u,i) => <li key={i}>{u}</li>);
        const chevronClass = "fa fa-fw mr-2 " + ((this.state.isOpen) ? 'fa-chevron-down' : 'fa-chevron-right');

        return (
            <div className='mb-4'>
                <Button
                    onClick={this.handleClick}
                    aria-controls="example-collapse-text"
                    aria-expanded={this.state.isOpen}
                    variant='danger'
                    block={true}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <i className={chevronClass} />
                            We were unable to test some URLs
                        </div>
                        <div>Test Failures: {this.props.failedUrls.length}</div>
                    </div>
                </Button>
                <Collapse in={this.state.isOpen}>
                    <div>
                        <Card className='pt-2'>
                            <Card.Body>
                                <p>We could not review some URLs. They may have been unavailable during the review, or protected by a login or CAPTCHA.</p>
                                <ul className='text-danger'>{failedUrls}</ul>
                            </Card.Body>
                        </Card>
                    </div>
                </Collapse>
            </div>
        );
    }
}

FailedUrlsComponent.propTypes = {
    failedUrls: PropTypes.array
};

export default FailedUrlsComponent;
