import React, { Component } from "react";
import homepage from "../images/homepage.png";
import urls from "../images/urls.png";
import addUrl from "../images/add_url.png";
import visualAppraisal from "../images/visual_appraisal.png";
import visualAppraisalMobile from "../images/visual_appraisal_mobile.png";
import visualAppraisalZoom from "../images/visual_appraisal_zoom.png";
import results from "../images/results.png";
import resultsOpen from "../images/results_open.png";
import statement from "../images/statement.png";
import submit from "../images/submit.png";
import progress from "../images/progress.png";

class HelpPage extends Component {
    componentDidMount() {
        document.title = 'User Guide · '+process.env.REACT_APP_SITE_TITLE;
    }

    image(url, alt) {
        return <div className='card border-dark mb-5'>
            <img className="card-img-top mw-100" src={url} alt={alt}/>
        </div>;
    }

    render() {
        return (
            <>
                <h1 className='text-center mb-4'>User Guide</h1>
                <div className="col-md-10 container">
                    <h2>Begin a Review</h2>
                    <p>
                        To begin a review of a website, enter the URL of the website you
                        wish to review.
                    </p>

                    {this.image(homepage, "Screenshot of homepage of Web3Access.")}

                    <hr />
                    <h2>Select URLs to be reviewed</h2>
                    <p>
                        The tool may have generated a number of URLs
                        which are accessible from the website which
                        you specified when you began the review. At this
                        stage you can select any number of URLs or add
                        a URL of your choice to be reviewed. This allows
                        you to review multiple parts of a website at a time.
                        If you wish to view the page for a URL in the list, you
                        can click the link icon at the end of the URL (Note: this
                        will open the URL in a new tab).
                    </p>

                    {this.image(urls, "Screenshot of page showing list of URLs to be selected for review.")}

                    <h3>Add a URL</h3>
                    <p>
                        To add a URL, simply enter a new URL into the text box provided and
                        press the &quot;Add URL&quot; button.
                    </p>
                    {this.image(addUrl, "Screenshot input to add URL for review.")}


                    <h3>Submit the URLs for review</h3>
                    <p>
                        Pressing the &quot;Start Review&quot; button will trigger the automated
                        accessibility checks to begin running on all of the selected
                        websites from the list below.
                    </p>

                    {this.image(submit, "Screenshot of 'Start Review' button.")}

                    <hr />
                    <h2>Visual Appraisal</h2>

                    <p>
                        <i>
                            Note: the visual appraisal tests may take a while to prepare. Unless you
                            see an error message, assume that the tests are still being prepared.
                        </i>
                    </p>


                    <p>
                        Once the visual appraisal tests have loaded, you will
                        be presented with a number of questions and images.
                        Answer the questions appropriately using the &quot;Yes&quot; and
                        &quot;No&quot; buttons at the bottom of the page. These tests are
                        used to check against accessibility guidelines and if your
                        response suggests a failure then the result will be shown
                        on the results page.
                    </p>

                    <p>
                        The system currently contains 3 visual appraisal tests:
                        <ul>
                            <li>Orientation</li>
                            <li>Text Spacing</li>
                            <li>Image Alt Tags</li>
                        </ul>
                        Please note that Text Spacing and Image Alt Tag tests may not always produce
                        images for the visual appraisal process because some automatic checks are
                        performed prior to raising them for review with the auditor. If no images
                        are presented, then it means that the URL passed the automatic checks
                        so it wasn&appos;t necessary to flag it for visual appraisal.
                        The orientation test will always be shown and will show the layout of
                        a page in both landscape and portrait orientation. You must determine
                        whether there is any loss of page content when in either orientation.
                        For example, in the example below, the buttons on the top right and
                        bottom right of the page are visible in the landscape orientation but
                        not in the portrait orientation.


                    </p>

                    {this.image(visualAppraisal, "Screenshot of visual appraisal page for orientation checks on a desktop computer.")}
                    <p>
                        The orientation test will display the page in portrait and
                        landscape layouts on multiple devices: desktop, tablet and mobile.
                    </p>
                    {this.image(visualAppraisalMobile, "Screenshot of visual appraisal page for orientation checks on mobile device.")}

                    <p>
                        The progress bar at the top of the page will inform you
                        of your progress through the visual appraisal tests.
                    </p>
                    {this.image(progress, "Screenshot of progress bar on visual appraisal page.")}

                    <p>
                        If you wish to zoom on an image, simply click on the image and it
                        will fill your screen. To minimise the image, click anywhere in the window.
                        You can also zoom on images like this using your keyboard by pressing
                        either the space key or enter key when the image is selected. Press the same
                        key again to minimise the image.
                    </p>
                    {this.image(visualAppraisalZoom, "Screenshot of a zoom functionality on the visual appraisal page.")}


                    <hr />
                    <h2>Results</h2>

                    <p>
                        <i>
                            Note: the results page can take a while to load if you&apos;re testing
                            a large webpage or lots of URLs. Unless you
                            see an error message, assume that the tests are still being performed.
                        </i>
                    </p>

                    <p>
                        Once you have completed the visual appraisals, the results
                        of both the automated tests and the visual appraisals will
                        be displayed. The results consist of accessibility issues
                        on the page under the Web2Access test checklist categories.
                        The number of issues for each category is listed on the
                        far right of each section. To open a category of results, simply
                        click on the button to reveal the issues.
                    </p>
                    {this.image(results, "Screenshot of results page.")}

                    <p>
                        Clicking on a category will reveal the issues as below.
                        To see the HTML element responsible for the issue, click
                        the &quot;Show Context&quot; button.
                    </p>
                    {this.image(resultsOpen, "Screenshot of results page with an expanded issue section.")}

                    <hr />
                    <h2>Accessibility Statement</h2>
                    <p>
                        The automatically generated accessibility statement is
                        available at the bottom of the results page under a
                        collapsible menu. To copy the HTML of the statement to
                        your clipboard, simply click the &quot;Copy statement HTML to
                        Clipboard&quot; button. The statement is editable but any changes
                        you make won&apos;t be saved to the system, so make sure to copy
                        it when you have finished.
                    </p>
                    {this.image(statement, "Screenshot of beginning of accessibility statement.")}
                    <br/>

                </div>
            </>
        );
    }
}

export default HelpPage;
