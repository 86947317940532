const categoryMap = {
    1: 'Login, Signup and Other Forms',
    2: 'Content Description and Text Alternatives',
    3: 'Link Target Definitions',
    4: 'Logical Page Structure and Order',
    5: 'Reading Order',
    6: 'Audio/Video Features',
    7: 'Appropriate use of Tables',
    8: 'Page Functionality with Keyboard and Touch Screen',
    9: 'Appropriate Feedback',
    10: 'Contrast and Colour Check',
    11: 'Page Integrity when Zooming, Resizing Text or using Device Motion',
    12: 'Language, Readability and Flashing elements'
};

export default categoryMap;
