import React, { Component } from "react";
import { Button, Collapse } from "react-bootstrap";
import ProblemComponent from "./ProblemComponent";
import { getWCAGUrl, getWCAGFormattedName } from "../../scripts/HelperFunctions";
import PropTypes from "prop-types";

class ResultItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            hasTechnique: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    componentDidMount() {
        if (this.props.technique !== undefined) {
            this.setState({
                hasTechnique: true
            });
        }
    }

    render() {
        let comps = [];
        let keyCount = 0;

        for (let problemIndex in this.props.problems) {
            let problem = this.props.problems[problemIndex];
            comps.push(<ProblemComponent key={keyCount} problem={problem} />);
            keyCount++;
        }

        // Make first letter of type upper case e.g. error -> Error
        let type = this.props.type;
        type = type[0].toUpperCase() + type.slice(1);

        let technique;
        if (this.state.hasTechnique) {
            technique = this.props.technique;
        } else {
            technique = 'N/A';
        }
        return (
            <div className="result-item">
                <dl className='row'>
                    <dt className="col-sm-3">WCAG Success Criterion</dt>
                    <dd className='col-sm-9'>
                        <a href={getWCAGUrl(this.props.guideline)} rel="noopener noreferrer" target="_blank">
                            {this.props.guideline} - {getWCAGFormattedName(this.props.guideline)}
                        </a>
                    </dd>

                    <dt className="col-sm-3">Technique</dt>
                    <dd className='col-sm-9'>{technique}</dd>

                    <dt className="col-sm-3">WCAG Conformance Level</dt>
                    <dd className='col-sm-9'>{this.props.level}</dd>

                    <dt className="col-sm-3">Message</dt>
                    <dd className='col-sm-9'>{this.props.message}</dd>

                    <dt className="col-sm-3">Type</dt>
                    <dd className='col-sm-9'>{type}</dd>
                </dl>
                <Button
                    onClick={this.handleClick}
                    aria-controls="example-collapse-text"
                    aria-expanded={this.state.isOpen}
                >
                    <div>Show Context</div>
                </Button>
                <Collapse in={this.state.isOpen}>
                    <div>{comps}</div>
                </Collapse>
            </div>
        );
    }
}

ResultItem.propTypes = {
    message: PropTypes.string,
    level: PropTypes.string,
    guideline: PropTypes.string,
    technique: PropTypes.string,
    type: PropTypes.string,
    problems: PropTypes.arrayOf(PropTypes.object)
};

export default ResultItem;
