import React, {Component} from "react";
import {Button, Card, Collapse} from "react-bootstrap";
import ResultItems from "./ResultItems";
import categoryMap from "../../scripts/CategoryMap";
import EvaluationItem from "./EvaluationItem";
import PropTypes from "prop-types";

class Web2AccessCategory extends Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        let numIssues = 0;
        if (this.props.result !== undefined && this.props.result.length > 0) {
            numIssues = Object.keys(this.props.result).length;
        }
        const chevronClass = "fa fa-fw mr-2 " + ((this.state.isOpen) ? 'fa-chevron-down' : 'fa-chevron-right');

        // TODO: Fix ARIA in <Button>

        return (
            <div className="mb-3">
                <Button
                    onClick={this.handleClick}
                    aria-controls="example-collapse-text"
                    aria-expanded={this.state.isOpen}
                    block={true}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <i className={chevronClass} />
                            {this.props.categoryId}.{" "}
                            {categoryMap[this.props.categoryId]}
                        </div>
                        <div>Issues: {numIssues}</div>
                    </div>
                </Button>
                <Collapse in={this.state.isOpen}>
                    <div>
                        <Card className='pt-2'>
                            <Card.Body>
                                <EvaluationItem result={this.props.result} w2aRate={this.props.w2aRate}/>
                                <ResultItems result={this.props.result} />
                            </Card.Body>
                        </Card>
                    </div>
                </Collapse>
            </div>
        );
    }
}

Web2AccessCategory.propTypes = {
    categoryId: PropTypes.string,
    result: PropTypes.array,
    w2aRate: PropTypes.number
};

export default Web2AccessCategory;
