import React, { Component } from "react";
import ResultItem from "./ResultItem";
import { findGuideline } from "../../scripts/HelperFunctions.js";
import PropTypes from "prop-types";

class ResultItems extends Component {
    render() {
        let items = [];

        // Clone results prop to ensure it isn't modified
        const results = [...this.props.result];
        // Sort results so errors are displayed in alphabetical order by guideline/issue code
        results.sort((a,b) => (a["code"] < b["code"]) ? -1 : 1);

        for (let issueIndex in results) {
            let issue = results[issueIndex];
            let code = issue["code"];
            // code is always in the form: WCAG2AA.Principle1.Guideline1_3.1_3_1.H49.Center
            // Split it by "." to get each element so we can select what to display to the user.
            let splitCode = code.split(".");
            // Convert guideline code in the form X_Y_Z to X.Y.Z
            let guideline = findGuideline(code);
            let technique = splitCode[4];
            let conformanceLevel = issue['conformance'];
            let message = issue["message"];
            let type = issue["type"];
            let urls = getUrls(issue["pageUrls"]);
            let problems = issue["problems"];
            if (issueIndex >= 1) {
                items.push(<hr key={issueIndex + code} />);
            }
            items.push(
                <ResultItem
                    key={issueIndex}
                    guideline={guideline}
                    technique={technique}
                    level={conformanceLevel}
                    message={message}
                    type={type}
                    urls={urls}
                    problems={problems}
                />
            );
        }

        return items;
    }
}

function getUrls(pageUrls) {
    let urlString = [];
    for (let urlIndex in pageUrls) {
        if (urlIndex >= 1) {
            urlString += ",\n";
        }
        urlString += pageUrls[urlIndex]["url"];
    }
    return urlString;
}

ResultItems.propTypes = {
    result: PropTypes.array
};

export default ResultItems;
