import React, {Component} from "react";
import Web2AccessCategories from "../components/ResultsPage/Web2AccessCategories";
import {getLastUpdated, getResults, getReview, getStatement, getUrl, sendReviewToWeb2Access} from "../scripts/ApiCalls";
import FailedUrlsComponent from "../components/ResultsPage/FailedUrlsComponent";
import AccessibilityStatement from "../components/ResultsPage/AccessibilityStatement";
import {Button, Spinner, Tab, Tabs} from "react-bootstrap";
import PropTypes from "prop-types";

class ResultsPage extends Component {
    // TODO prevent cross-site scripting.
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            results: null,
            failedUrls: [],
            url: null,
            lastUpdated: null
        };

        this.checkResults = this.checkResults.bind(this);
        this.urlList = this.urlList.bind(this);
    }

    async componentDidMount() {
        document.title =  'Results · ' + process.env.REACT_APP_SITE_TITLE;
        const review_id = this.props.match.params.review_id;
        await this.checkResults(review_id, 1000);
    }

    async sendToWeb2Access(e) {
        console.log(this, e);
        sendReviewToWeb2Access(this.state.review);
    }

    /**
     * Check if the results are ready/complete using increasing timeouts.
     *
     * @param {String} review_id the ID of the review.
     * @param {Number} timeout how long to wait to try to retrieve results again (in ms).
     */
    async checkResults(review_id, timeout) {
        // console.log('check results');
        if (this.props.status === 5) {
            // Can only get aggregated results once manual and automatic review are both complete (i.e. status 4)
            const results = await getResults(review_id);
            // Check if we got the results from the API.
            if (!results.error) {
                const review = await getReview(review_id);

                let url = await getUrl(review_id);

                // Check if we got the URL from the API.
                if (!url.error && url.data != null) {
                    url = url.data.site_url;

                    let lastUpdated = await getLastUpdated(review_id);
                    if (!lastUpdated.error && lastUpdated.data != null) {
                        lastUpdated = lastUpdated.data.last_updated;
                        let date = lastUpdated.slice(0, 10);
                        let day = date.slice(8, 10);
                        let month = date.slice(5, 7);
                        let year = date.slice(0, 4);
                        date = day + "/" + month + "/" + year;
                        let time = lastUpdated.slice(11, 16);
                        lastUpdated = time + ", " + date;

                        const statementData = await getStatement(review_id);
                        if (!statementData.error && statementData.data != null) {
                            let statementData_json = statementData.data.statement;
                            this.setState({
                                results: results.data.results,
                                failedUrls: results.data.null_urls,
                                isLoaded: true,
                                url: url,
                                lastUpdated: lastUpdated,
                                statementData: statementData_json,
                                review: review
                            });
                        } else {
                            if (statementData.data !== null && statementData.data.message !== undefined) {
                                this.props.updateError(true, statementData.data.message);
                            } else if (statementData.status === null) {
                                let msg =
                                    "Could not retrieve the accessibility statement for the review with id: " +
                                    review_id;
                                this.props.updateError(true, msg);
                            }
                            this.props.updates();
                            this.scheduleCheckResults(review_id, timeout);
                        }
                    } else {
                        if (lastUpdated.data !== null && lastUpdated.data.message !== undefined) {
                            this.props.updateError(true, lastUpdated.data.message);
                        } else if (lastUpdated.status === null) {
                            let msg =
                                "Could not retrieve the last updated date for the review with id: " +
                                review_id;
                            this.props.updateError(true, msg);
                        }
                        this.props.updates();
                        this.scheduleCheckResults(review_id, timeout);
                    }
                } else {
                    if (url.data !== null && url.data.message !== undefined) {
                        this.props.updateError(true, results.data.message);
                    } else if (url.status === null) {
                        let msg =
                            "Could not retrieve the URLs for the review with id: " +
                            review_id;
                        this.props.updateError(true, msg);
                    }
                    this.props.updates();
                    this.scheduleCheckResults(review_id, timeout);
                }
            } else {
                if (results.data !== null && results.data.message !== undefined) {
                    this.props.updateError(true, results.data.message);
                } else if (results.status === null) {
                    let msg =
                        "Could not retrieve the results for the review with id: " +
                        review_id;
                    this.props.updateError(true, msg);
                }
                this.props.updates();
                this.scheduleCheckResults(review_id, timeout);
            }
            if(this.state.url){
                document.title = `Review Results for ${this.state.url}`;
            }
        } else {
            this.scheduleCheckResults(review_id, timeout);
        }
    }

    scheduleCheckResults(reviewId, currentTimeout, maxTimeout = 5000) {
        setTimeout(() => {
            // console.log("Timeout at" + currentTimeout);
            const newTimeout = Math.min(currentTimeout * 1.5, maxTimeout);
            // If we're at the MAX_TIMEOUT, reload the page.
            if (newTimeout >= maxTimeout) {
                window.location.reload();
            } else {
                this.checkResults(reviewId, newTimeout);
            }
        }, currentTimeout);
    }

    urlList() {
        let urls = this.state.statementData.urls;
        if (urls.length === 1) {
            return <a key={urls[0]} href={urls[0]}>{urls[0]}</a>;
        }
        let anchors = [];
        for (let i = 0; i < urls.length - 1; i++) {
            anchors.push(<span key={urls[i]}><a key={urls[i]} href={urls[i]}>{urls[i]}</a>, </span>);
        }
        anchors.push(<a key={urls[urls.length - 1]} href={urls[urls.length - 1]}>{urls[urls.length - 1]}</a>);
        return anchors;
    }

    render() {
        if (this.state.isLoaded && !this.state.isError) {
            const failedUrls = this.state.failedUrls ? this.state.failedUrls : [];

            // Display a Web2Access button if this review was initiated from Web2Access
            let w2aButton = '';
            if(this.state.review.web2access_id) {
                w2aButton = <Button href={`${process.env.REACT_APP_WEB2ACCESS_URL}/reviews/${this.state.review.web2access_id}/summary`} target='_blank'>
                    Return to Web2Access
                </Button>;
            }

            return (
                <>
                    <div className="d-flex align-items-center justify-content-between">
                        <h1>
                            Results <small className="text-muted">for {this.state.review.site_url}</small>
                        </h1>
                        {w2aButton}
                    </div>
                    {/*<Button onClick={this.sendToWeb2Access.bind(this)}>Send to Web2Access</Button>*/}
                    <p>All URLs reviewed: {this.urlList()}</p>
                    <p>Last updated: {new Intl.DateTimeFormat("en-GB", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit"
                    }).format(this.state.review.last_updated)}</p>
                    <FailedUrlsComponent failedUrls={failedUrls} />

                    <Tabs id='review-results' defaultActiveKey="results">
                        <Tab eventKey="results" title="Results">
                            <div className='mt-2'>
                                <Web2AccessCategories results={this.state.results} w2aRates={this.state.statementData.web2accessRates}/>
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="Accessibility Statement">
                            <AccessibilityStatement
                                lastUpdated={this.state.lastUpdated}
                                url={this.state.url}
                                statementData={this.state.statementData}
                                failedUrls={failedUrls}
                            />
                        </Tab>
                    </Tabs>
                </>
            );
        } else {
            return (
            <div className="text-center">
                <Spinner animation="border" variant='primary' role="status" className='mt-5 mb-4'>
                    <span className="sr-only">Loading&hellip;</span>
                </Spinner>
                <p>Running Final Tests&hellip;</p>
                <p className='text-muted'>This could take a few minutes</p>
            </div>
            );
        }
    }
}

ResultsPage.propTypes = {
    status: PropTypes.number,
    updateError: PropTypes.func,
    updates: PropTypes.func,
    match: PropTypes.object
};

export default ResultsPage;
