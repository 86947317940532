import React, {Component} from 'react';
import {crawlSite, getUrl, startReview} from '../scripts/ApiCalls';
import Checkbox from '../components/ReviewPage/Checkbox';
import DefaultError from "./error/DefaultError";
import {Button, Col, Form, FormControl, InputGroup, Row, Spinner} from "react-bootstrap";
import PropTypes from "prop-types";

class ReviewPage extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            review_id: this.props.match.params.review_id,
            urls: [],
            errorMessage: null,
            errorCheck: false,
            selectedUrls: new Set(),
            isLoaded: false,
        });

        this.addUrlInput = React.createRef();

        this.renderCheckboxes = this.renderCheckboxes.bind(this);
        this.addUrl = this.addUrl.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        document.title =  'Select Review URLs · ' + process.env.REACT_APP_SITE_TITLE;
        const review_id = this.props.match.params.review_id;
        const getUserURL = await getUrl(review_id);

        if (!getUserURL.error && getUserURL.data !== null && getUserURL.data.site_url !== null) {
            const user_url = getUserURL.data.site_url;

            const startCrawl = await crawlSite(user_url, 1);

            if (!startCrawl.error && startCrawl.data !== null && startCrawl.data.urls !== null) {
                const urls = startCrawl.data.urls;
                this.setState({
                    urls: urls,
                    selectedUrls: new Set([user_url]),
                    isLoaded: true,
                });

            }
            else {
                let note;
                if (startCrawl.data !== null && startCrawl.data.message !== null) {
                    note = startCrawl.data.message;
                } else {
                    note = "There happens to be an issue in crawling for urls.";
                }

                this.setState({
                    errorMessage: note,
                    errorCheck: true,
                    isLoaded: true,
                });
            }
        } else {
            let note;
            if (getUserURL.data !== null && getUserURL.data.message !== null) {
                note = getUserURL.data.message;
            } else {
                note = 'Issue in retrieving URL of review.';
            }

            this.setState({
                errorMessage: note,
                errorCheck: true,
                isLoaded: true,
            });
        }
    }

    renderCheckboxes() {
        const urls = this.state.urls;
        const selectedUrls = this.state.selectedUrls;
        return urls.map((u) => this.createCheckbox(u, selectedUrls.has(u)));
    }

    createCheckbox(url, isChecked) {
        return <Checkbox
            label={url}
            handleChange={this.handleChange}
            key={url+isChecked} // Without having isChecked in the key, react will not rerender as it assumes the component hasnt changes
            isChecked={isChecked}
        />;
    }

    addUrl = async event => {
        event.preventDefault();

        let url = this.addUrlInput.current.value;
        if(/^\s*$/.test(url)){
            this.props.updateError(
                true,
                'You can\'t add a blank url to the checklist');
            return;
        }
        let hasCorrectFormat = /^(?:(?:https?:\/\/)(?:www\.)?(?:[a-zA-Z0-9.-]+\.(?:[a-zA-Z0-9]+|(?:xn|XN)--[a-zA-Z0-9]+)))[^a-zA-Z0-9.-<>]*[^<>]*$/.test(url);
        if (!hasCorrectFormat) {
            this.props.updateError(
                true,
                'The provided url ' + url + ' is of incorrect format, correct format should include the protocol (http or https), as in http://google.com or https://google.com');
            return;
        }
        let urls = this.state.urls;

        if (urls.includes(url)) {
            this.props.updateError(true, "The submitted url is already in the checkbox list");
            return;
        }

        let selectedUrls = this.state.selectedUrls;
        urls.unshift(url);
        selectedUrls.add(url);
        this.setState({
            urls: urls,
            selectedUrls: selectedUrls
        });
        this.addUrlInput.current.value = '';
    }

    handleChange(isChecked, url) {
        let prevSelected = this.state.selectedUrls;
        if (isChecked && !prevSelected.has(url)) {
            prevSelected.add(url);
            this.setState({
                selectedUrls: prevSelected
            });
        } else if (!isChecked && prevSelected.has(url)) {
            prevSelected.delete(url);
            this.setState({
                selectedUrls: prevSelected
            });
        }
    }

    //Start performing review on selected urls after submitting
    handleSubmit = async submitEvent => {
        submitEvent.preventDefault();

        //Redirect user to next page which indicates review is in progress
        let url_array = Array.from(this.state.selectedUrls);
        const response = await startReview(this.state.review_id, url_array);

        const review_id = this.state.review_id;

        if (!response.error) {
            this.setState({
                review_id: review_id,
                errorMessage: null,
                errorCheck: false,
            });

            this.props.updates();
        } else {
            let note;
            if (response.data !== null && response.data.message !== null) {
                note = response.data.message;
            } else {
                note = 'There has been an issue in commencing the review on chosen URLs.';
            }
            const selectedUrlsClone = new Set(this.state.selectedUrls);
            if(response.data !== null && response.data.bad_urls != null){
                const bad_urls = response.data.bad_urls;
                for(let i = 0; i < bad_urls.length; i++){
                    selectedUrlsClone.delete(bad_urls[i]);
                }
            }
            this.setState({selectedUrls:selectedUrlsClone});

            this.props.updateError(true, note);
        }
    }

    render() {
        const checkboxes = this.renderCheckboxes();
        if(!this.state.isLoaded){
            return <div className='text-center'>
                <Spinner animation="border" variant='primary' role="status" className='mt-5 mb-4'>
                    <span className="sr-only">Loading...</span>
                </Spinner>
                <p>Checking URL&hellip;</p>
            </div>;
        }
        if (!this.state.errorCheck) {
            return (
                <div className="container">
                    <Row className="justify-content-center">
                        <Col sm={10} md={6}>
                            <h1 className="text-label text-center">Select URLs to Test</h1>
                            <p className='lead text-center'>Which URLs should we run tests on?</p>
                            <Form onSubmit={this.addUrl} className='mt-5'>
                                <Form.Group controlId='testUrl'>
                                    <Form.Label className='h4'>Add a Custom URL</Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            placeholder='e.g. https://website.com'
                                            ref={this.addUrlInput}
                                            required />
                                        <InputGroup.Append>
                                            <Button variant='primary' type="submit" value="Add URL">
                                                Add URL
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Form>
                            <br/>
                            <Form onSubmit={this.handleSubmit}>
                                <h2 className='h4'>Select Detected URLs</h2>
                                {checkboxes}
                                <div className="text-center mt-3">
                                    <Button variant='primary' type="submit" value="Add URL">
                                        Start Review <i className="fa fa-fw fa-chevron-right ml-1"/>
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </div>
            );
        } else if (this.state.errorCheck) {
            return <DefaultError title={"Please try again later"} errorMessage={this.state.errorMessage} />;
        }
    }
}

ReviewPage.propTypes = {
    updateError: PropTypes.func,
    updates: PropTypes.func,
    match: PropTypes.object
};

export default ReviewPage;
